































































































import './scss/PopupAgreement.scss';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { makeBlur } from '@utility/makeBlur';

import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';
import { ICustomUserPackPDAction, IPackPDComment } from '@store/modules/project-documents/Interfaces';
import { IDocument } from '@store/modules/project-documents/Interfaces';
import { IProject } from '@store/modules/project/Interfaces';

const NSProject = namespace('storeProject');
const NSDocuments = namespace('storeProjectDocuments');

@Component({
    name: 'PopupAgreement',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseTextarea: () => import('@components/BaseInputs/BaseTextarea.vue'),
        BasePreloader: () => import('@components/BasePreloader/BasePreloader.vue'),
    },
})
export default class PopupAgreement extends Vue {
    $refs!: { basePopup: any }

    @NSDocuments.Action(ProjectDocumentsActions.A_GET_SET_ACTIONS) getSetActions!: (payload) => Promise<void>;
    @NSDocuments.Action(ProjectDocumentsActions.A_SET_PACK_PD_ACTIONS) setAgreement!: (payload) => Promise<void>;
    @NSProject.Getter('projectData') projectData!: IProject;

    subtitle: string = '';
    setId: string = '';
    stageId: string = '';
    templateId: string = '';
    documentsInSelectedSet: IDocument[] = [];
    signsList: any[] = [];

    gettingActionsAndComments: boolean = false;
    actions: ICustomUserPackPDAction[] = [];
    oldComments: IPackPDComment[] = [];
    pendingAction: null|number = null;

    errors = {
        action: false,
        comment: false,
    };

    fields = {
        action: null as null|number,
        newComment: '',
    }

    btnDisabled: boolean = false;
    realtimeValidation: boolean = false;

    get errorExists() {
        return Object.values(this.errors).some((item) => item);
    }

    get selectedNegativeAction() {
        if (this.fields.action) {
            const selectedAction = this.actions.filter(item => item.id === this.fields.action)[0];
            return selectedAction.isNegative;
        }
        return false;
    }

    get btnDisabledComputed() {
        if (!this.btnDisabled) {
            return this.selectedNegativeAction ? !this.fields.newComment.length : !this.fields.action;
        }
        return true;
    }

    resetFields() {
        this.fields = {
            action: null,
            newComment: '',
        }
        this.signsList = [];
    }

    resetErrors() {
        this.errors = {
            action: false,
            comment: false,
        };
    }

    closePopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.checkClosePopup();
        }
        this.resetFields();
        this.resetErrors();
        this.realtimeValidation = false;
        this.$emit('close');
    }

    openPopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.openPopup();
        }
    }

    checkActionsAndComments() {
        this.gettingActionsAndComments = true;
        this.getSetActions({
            appId: this.projectData.id,
            id: this.setId,
        }).then((data: any) => {
            if (data.actions && data.comments) {
                this.openPopup();
            } else {
                this.$emit('missing-data-actions');
            }
            this.actions = data.actions || [];
            this.oldComments = data.comments || [];
            this.stageId = data.stageId;
            this.templateId = data.templateId;
        }).finally(() => {
            this.gettingActionsAndComments = false;
        })
    }

    setData(data) {
        this.subtitle = data.title;
        this.setId = data.id;
        this.documentsInSelectedSet = data.docs;
    }

    setSigns(data) {
        this.signsList = data;
        this.fields.action = this.pendingAction;
        this.pendingAction = null;
    }

    validateFields() {
        this.errors.action = !this.fields.action;
        if (this.selectedNegativeAction) {
            this.errors.comment = !this.fields.newComment.length;
        }
        this.realtimeValidation = true;
    }

    checkValidation() {
        if (this.realtimeValidation) {
            this.validateFields();
        }
    }

    onSuccess() {
        this.validateFields();
        if (!this.errorExists) {
            this.btnDisabled = true;
            let payload: any = {
                appId: this.projectData.id,
                id: this.setId,
                stageId: this.stageId,
                templateId: this.templateId,
                actionId: this.fields.action,
                comment: this.fields.newComment,
            };
            if (this.signsList && this.signsList.length) {
                payload = {...payload, signs: this.signsList}
            }
            this.setAgreement(payload).then(() => {
                this.closePopup();
                this.resetErrors();
                this.realtimeValidation = false;
                this.$emit('agreement-success');
            });

            this.btnDisabled = false;
        }
    }

    selectAction(item) {
        if (item.signRequired) {
            this.pendingAction = item.id;
            this.$emit('open-sign-agreement-popup', {
                docs: this.documentsInSelectedSet,
                filesIds: item.signData?.files,
                id: this.setId,
                signData: item.signData?.docData,
            });
        } else {
            if (this.fields.action === item.id) {
                this.fields.action = null;
            } else {
                this.fields.action = item.id;
            }
            makeBlur();
            this.checkValidation();
        }
    }

    getDate(data) {
        const date = new Date(data);
        let day: string | number = date.getDate();
        let month: string | number = date.getMonth() + 1;
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return `${day}.${month}.${date.getFullYear()}`;
    }

    getTime(data) {
        const date = new Date(data);
        return `${date.getHours()}:${date.getMinutes()}`;
    }
}
